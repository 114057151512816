:root {
  --orange: #ff5f05;
  --blue: #236ecf;
}

.about-container {
  padding-top: 11rem;
}

/* About Header */
.about-header {
  font-family: "MonoRegular";
  line-height: 3rem !important;
}

.picture {
  object-fit: contain;
}

.golden {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-left: 1rem;
  z-index: -1;
  width: 200px;
}

.sf {
  position: absolute;
  top: 0;
  width: 100%;
}

.gg {
  width: 200px;
  height: 50px;
  opacity: 30%;
  animation: ocean 5s ease-in-out infinite;
  margin-left: 1.5rem;
}

.swe {
  font-size: 1em;
  position: relative;
  z-index: -1;
}
.swe::after {
  border-bottom: 8px solid #c54e6acc;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 65%;
  z-index: -2;
  border-radius: 10px;
}

.cap {
  width: 150px;
  margin-bottom: 15px;
}

.vid-section {
  margin-top: 5.5rem !important;
  margin-bottom: 4rem;
}

@keyframes ocean {
  0% {
    transform: skew(10deg,1deg);
  }
  50% {
    transform: skew(-10deg,-1deg);
  }
  100% {
    transform: skew(10deg,1deg);
  }
}

@media (max-width: 767px) {
  .about-container {
    padding-top: 8rem;
  }

  .about-header, .about-body {
    text-align: center;
    margin-bottom: 0 !important;
  }

  .golden {
    margin-left: 1rem !important;
  }

  .gg {
    margin-left: 0.5rem !important;
    transform: scale(0.85) !important;
  }

  .picture {
    margin-bottom: 1rem;
  }

  .vid-section {
    margin-top: 3rem !important;
  }
}

@media (max-width: 467px) {
  .golden {
    margin-left: 0 !important;
    margin: auto;
  }
}

@media (max-width: 991px) {
  .golden {
    margin-left: 0;
  }
}

/* About Body */
.about-body {
  margin-bottom: 1rem !important;
  line-height: 2rem !important;
  font-family: "MSBee";
}

.activity {
  width: 25px;
}

/* video */
.vid {
  width: 100%;
  margin-bottom: -0.4rem;
  margin-left: 0.5rem;
}

.vid-row {
  padding: 0 !important;
}
