@font-face {
  font-family: 'MonoRegular';
  src: local('MonoRegular'), url("./assets/fonts/MonoRegular.woff2") format('woff');
}

@font-face {
  font-family: 'MSBee';
  src: local("MSBee"), url("./assets/fonts/MSBee-Regular.woff2") format('woff');
}

@font-face {
  font-family: 'Inter';
  src: local("Inter"), url("./assets/fonts/InterRoman.woff2") format('woff');
}

:root {
  --primary-text: whitesmoke;
  --secondary-text: #df6a86;
  --cursor-inner: yellow;
  --cursor-outer: #eee48b;
  --cursor-inner1: yellowgreen;
  --cursor-outer1: #52632f;
}

.pink {
  color: var(--secondary-text);
}

body {
  margin: 0;
  font-family: "MonoRegular" !important;
  background: linear-gradient(-45deg, rgb(12, 1, 22), rgb(15, 0, 15), rgb(15, 4, 10), rgb(6, 1, 22));
  background-size: 400% 400%;
  animation: gradient 4s ease infinite;
  color: var(--primary-text) !important;
  overflow-y: scroll;
  overflow-x: hidden;
  /* background-image: linear-gradient(to bottom right, rgb(13, 3, 20), rgb(23, 9, 36)); */
  /* background-attachment: fixed; */
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: linear-gradient(-45deg, rgb(12, 1, 22), rgb(15, 0, 15), rgb(15, 4, 10), rgb(6, 1, 22));
}

body::-webkit-scrollbar-thumb {
  background-color: #5a5547;
  border-radius: 20px;
}

.react-reveal {
  animation-fill-mode: backwards !important;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@keyframes gradient {
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
