.projects-container {
  --border-rad: 20px;
  --img-rad: 15px;
  --card-color: #144717; 
  --mid-color: #DE7F3B;
  --back-color: #236ecf;
  --header-color: #df6a86;
  --body-color: #f5f5f5;
  --yellow: #eee48b;
  --card-height: 100%;
  padding-top: 10rem;
}

.project-header {
  margin-bottom: 1rem;
}

.projects-row {
  margin-bottom: 7rem !important;
}

.card-container {
  width: 80%;
}

.proj-header {
  font-size: 2.5rem;
  position: relative;
  z-index: -1;
  color: var(--back-color);
  -webkit-text-stroke: 0.1px var(--body-color);
  font-weight: 600;
  text-align: center;
}

.proj-header::after {
  border-bottom: 8px solid var(--mid-color);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 69%;
  z-index: -2;
  border-radius: 10px;
}

@media (max-width:991px) {
  .projects-container {
    padding-top: 8rem;
  }

  .project-header {
    margin-bottom: 0;
  }

  .proj-header {
    width: 80%;
  }
  .proj-header::after {
    top: 100%;
    transform: scaleX(0.8);
  }

  .card-container {
    margin-bottom: 2rem;
  }

  .projects-row {
    margin-bottom: 0 !important;
  }
}