:root {
  --link-hover: #df6a86;
  --nav-left: #1d1e2e5d;
  --nav-right: #04151d5d;
}

.fixed {
  width: 100%;
  position: fixed;
  z-index: 1;
}

/* Sticky navbar */
.navbar {
  transition: all 0.4s ease-out;
  padding: 0.3rem 2rem !important;
}

.logo {
  height: 3em;
  width: 3em;
}

.sticky {
  box-shadow: 0 5px 5px rgba(6, 1, 29, 0.349);
  backdrop-filter: blur(10px);
  transition: all 0.4s ease-out;
}

.navbar span {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.4s ease-out;
  background: linear-gradient( to right, var(--nav-left), var(--nav-right));
}

.sticky span {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.4s ease-out;
  background: linear-gradient( to right, var(--nav-left), var(--nav-right));
}

.curr-tab {
  /* opacity: 60%; */
}

/* Toggler animation */
.navbar-toggler {
  width: 48px;
  height: 48px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: -0.5rem;
  padding-bottom: 0;
  padding-right: 0;
  display: flex;
  position: relative;
}

.navbar-toggler:focus {box-shadow: none !important;}

.toggle1, .toggle3 {
  background-color: var(--link-hover);
  height: 2px;
  width: 24px;
  margin-top: 5px;
  margin-bottom: 5px;
  transition: all 0.15s ease-in-out !important;
}

.toggle2, .toggle2-1 {
  background-color: var(--link-hover);
  height: 2px;
  width: 24px;
  transition: all 0.2s ease-in-out !important;
  transition-delay: 0s !important;
}

.toggle2 {
  margin-top: 1px;
  margin-bottom: 1px;
}

.navbar-toggler:is(.collapsed) {
  .toggle1, .toggle3 {
    transition-delay: 0.35s !important;
  }
}

.navbar-toggler:not(.collapsed) {
  .toggle1, .toggle3 {
    width: 0px;
    transition-delay: 0s !important;
  }
}

.navbar-toggler:not(.collapsed) .toggle2 {
  transform: rotateZ(45deg);
  transition-delay: 0.35s !important;
}

.navbar-toggler:not(.collapsed) .toggle2-1 {
  transform: rotateZ(90deg);
  transition-delay: 0.35s !important;
}

/* Link hover */
.nav-link {
  color: whitesmoke !important;
  z-index: 10;
}

.navbar-collapse a:link {
  font-family: "MonoRegular";
  font-size: 1.2rem;
  margin-left: 1.8em;
  text-align: center;
  z-index: 10;
}

.navbar-collapse a:hover {
  color: var(--link-hover) !important;
  z-index: 10;
}

.navbar-collapse a {
  position: relative;
	transition: color 0.3s ease-in-out;
  z-index: 10;
}

.navbar-collapse a::before {
  content: '';
  position: absolute;
  top: 82%;
  width: 88%;
  height: 3px;
  background-color: var(--link-hover) !important;
  transform: scaleX(0);
  transition: transform .3s ease-in-out;
  z-index: 10;
  border-radius: 10px;
}

.navbar-collapse a:hover::before {
  transform: scaleX(1);
  z-index: 10;
}

@media (max-width: 991px) {
  .navbar-collapse a::before {
    top: 88%;
    left: 10%;
    width: 80%;
  }
}

/* Mobile nav dropdown */
@media (max-width: 767px) {
  .navbar {
    background: linear-gradient( to right, #04151d, #060824);
    text-align: center;
    box-shadow: 0 5px 5px rgba(6, 1, 29, 0.349);
  }

  .navbar-collapse {
    margin: -0.5rem 0 0.25rem !important;
  }

  .navbar-collapse a:link {
    margin-left: 0;
  } 

  .navbar-collapse a::before {
    display: none;
  }

  .navbar-collapse a:hover {
    color: whitesmoke;
    z-index: 10;
  }

  .sticky span {
    display: none;
  }
}
